var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"dataTable",staticClass:"elevation-0",attrs:{"id":"dataTable","headers":_vm.filterHeaders,"items":_vm.value,"item-class":_vm.playItemStyle,"hide-default-footer":"","disable-sort":"","search":_vm.search,"no-results-text":"没有找到匹配项","items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"fixed-header":"","custom-filter":_vm.customFilter},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"current-items":_vm.getFilteredItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top",[_c('div',{staticClass:"d-flex align-end pb-4 px-3"},[_c('span',{staticClass:"light-blue--text text--accent-4 text-h5"},[_vm._v(_vm._s('"' + _vm.title + '"'))]),_c('span',{staticClass:"teal--text text--accent-4 font-italic text-caption mx-4"},[_c('span',{domProps:{"textContent":_vm._s(_vm.value.length)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredItems.length !== _vm.value.length),expression:"filteredItems.length !== value.length"}]},[_vm._v(_vm._s(' • ' + _vm.filteredItems.length))])]),_vm._t("default"),_c('v-spacer'),_c('v-sheet',{attrs:{"width":"200"}},[_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2)])]},proxy:true},{key:"header.btns",fn:function(){return [_vm._t("header.btns",[_c('button-play',{attrs:{"id":_vm.filteredItems.map(function (res) { return res.id; }),"disable":_vm.loading || !_vm.filteredItems.length,"tip":"播放所有"}})])]},proxy:true},{key:"item.count",fn:function(ref){
var item = ref.item;
return [([1, 4].includes(item.privilege.fee))?_c('span',{staticClass:"text-caption red--text"},[_vm._v("vip")]):_c('span',{domProps:{"textContent":_vm._s(item.count)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('mv',{attrs:{"songid":item.id,"mvid":item.mv},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticStyle:{"min-width":"12vw"}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.name)}}),(item.alia.length)?_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(' - ' + item.alia[0]))]):_vm._e()])])]}}],null,true)})]}},{key:"item.artists",fn:function(ref){
var item = ref.item;
return _vm._l((item.artists),function(_item,index){return _c('span',{key:index},[(index !== 0)?_c('span',[_vm._v(" / ")]):_vm._e(),_c('button',{domProps:{"textContent":_vm._s(_item.name)},on:{"click":function($event){return _vm.lookArtists(_item.id)}}})])})}},{key:"item.album",fn:function(ref){
var album = ref.item.album;
return [(album.id)?_c('button',{staticClass:"text-truncate",staticStyle:{"max-width":"12vw"},attrs:{"title":album.name},domProps:{"textContent":_vm._s(album.name)},on:{"click":function($event){return _vm.lookAlbum(album.id)}}}):_vm._e()]}},{key:"item.dt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$time.song(item.dt)))])]}},{key:"item.btns",fn:function(ref){
var item = ref.item;
return [_vm._t("item.btns",[_c('div',{staticClass:"d-flex justify-end"},[_vm._t("item.btn.before",null,null,item),_vm._t("item.btn.one",[_c('button-add',{attrs:{"id":item.id,"notHave":item.privilege.st < 0 && !item.privilege.cs}})],null,item),_c('button-play',{attrs:{"id":[item.id],"name":item.name,"disable":item.id === _vm.id,"rClick":"","tip":"右键添加到下一首播放"}})],2)],null,{ item: item })]}},{key:"footer",fn:function(){return [(_vm.pageCount > 1)?_c('v-pagination',{staticClass:"my-3",attrs:{"length":_vm.pageCount,"total-visible":11,"circle":"","color":"blue lighten-2"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }